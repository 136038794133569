import React, { Component } from 'react';
import Moment from 'moment';
import DatePicker from "react-datepicker";

import 'bulma';
import ConfigOptionInput from './components/ConfigOptionInput';
import { AppContext } from './context';
import netlifyIdentity from 'netlify-identity-widget';
import _ from 'lodash';
// import ReactGA from 'react-ga';

import './App.css';
import 'bulmaswatch/darkly/bulmaswatch.min.css';

// ReactGA.initialize(process.env.REACT_APP_GA_ID);

const netlifyAuth = {
    isAuthenticated: false,
    user: null,
    authenticate(callback) {
        this.isAuthenticated = true;
        netlifyIdentity.open();
        netlifyIdentity.on('login', user => {
            this.user = user;
            callback(user);
        });
    },
    signout(callback) {
        this.isAuthenticated = false;
        netlifyIdentity.logout();
        netlifyIdentity.on('logout', () => {
            this.user = null;
            callback();
        });
    }
};

const yaml = require('js-yaml');
const defaultData = require('./default-data.json');
const inputClasses = 'input has-background-grey-lighter has-text-dark';

export default class App extends Component {
    constructor(props) {
        super(props);

        const startDate = this.setStartDate(new Date());
        this.state = {
            yaml: '',
            ourData: {
                name: ''
            },
            theirData: {},
            startDate,
            eventDescription: '',
            currentTab: 'form',
            formOnly: false,
            processModalIsOpen: false,
            pathWithFocus: undefined,
        };
        this.yaml = React.createRef();
        this.json = React.createRef();
        this.refreshStringArray = _.debounce(this.refreshStringArrayReal);
    }

    componentDidMount = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const paramJSON = urlParams.get('json');
        const formOnly = urlParams.get('formOnly');
        const savedJSON = localStorage.getItem('ourData');

        if (formOnly) {
            this.setState({
                formOnly: true,
            });
        }
        if (paramJSON) {
            // console.log('paramJSON');
            // console.log(this.parseJSONParam(paramJSON));
            this.setState({
                theirData: this.parseJSONParam(paramJSON),
            });
        }
        if (savedJSON) {
            this.setState({
                ourData: JSON.parse(savedJSON),
            });
        } else {
            this.setState({
                ourData: defaultData,
            });
        }

        // ReactGA.pageview(window.location.pathname + window.location.search);
    }

    saveState = () => {
        localStorage.setItem('ourData', JSON.stringify(this.state.ourData));
        localStorage.setItem('theirData', JSON.stringify(this.state.theirData));
    }

    setStartDate = (value) => {
        const date = Moment(value).utc().startOf('day').toISOString();
        // const date = Moment().utc().startOf('day').toDate();
        // console.log({date});
        // const date2 = new Date(date.setUTCHours(0, 0, 0, 0));
        // console.log({date2});
        // console.log((new Date(value)).setUTCHours(0, 0, 0, 0));
        return date;
        // const startDate = Moment((new Date()).setUTCHours(0, 0, 0, 0))
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (yaml.safeDump(this.state.ourData) !== this.state.yaml) {
            this.setState({
                yaml: yaml.safeDump(this.state.ourData),
            });
        }
        if (JSON.stringify(this.state.ourData) !== this.state.json) {
            this.setState({
                json: JSON.stringify(this.state.ourData),
            });
        }
        this.saveState();
    }

    getPath = (obj, path) => {
        var result = obj;
        path.forEach(key => result = result[key]);
        return result;
    };

    setPath = (obj, path, value) => {
        if (path.length > 1) {
            this.setPath(obj[path[0]], path.slice(1), value);
        } else {
            obj[path[0]] = value;
        }
    };

    removePath = (obj, path) => {
        if (path.length > 1) {
            this.removePath(obj[path[0]], path.slice(1));
        } else {
            const n = parseInt(path[0], 10);
            if (!_.isNaN(n)) {
                _.pullAt(obj, n);
            }
        }
    };

    handleDateSettingsChange = (name, value) => {
        if (name === "startDate") {
            this.setState((previousState) => {
                const newState = { ...previousState };
                newState.startDate = this.setStartDate(value);
                // console.log({newState});
                newState.ourData.dates.map((date, i) => {

                    // date.date = value;
                    date.date = Moment(newState.startDate).add(i, 'days').toISOString();
                    // console.log(Moment(value).add(i, 'days').toISOString());
                    // date.date = Moment(value).add(i + ' day');
                    // console.log({date});
                    return date;
                });
                // console.log({newState, value});
                // this.setPath(newState.ourData, path, typeof this.getPath(newState.ourData, path) == 'boolean' ? checked : value);
                return newState;
            });
        }
    }

    handleSettingsChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        // console.log({target, name, value});
        // if (name === "startDate") {
        //     console.log({value});
        //     this.setState((previousState) => {
        //         const newState = { ...previousState };
        //         newState.startDate = Moment(value.toISOString());
        //         newState.ourData.dates.map((date, i) => {
        //             // date.date = value;
        //             date.date = Moment(value).add(i, 'days').toISOString();
        //             // console.log(Moment(value).add(i, 'days').toISOString());
        //             // date.date = Moment(value).add(i + ' day');
        //             // alert(date);
        //             return date;
        //         });
        //         // this.setPath(newState.ourData, path, typeof this.getPath(newState.ourData, path) == 'boolean' ? checked : value);
        //         return newState;
        //     });
        // } else {
            this.setState({ [name]: value });
        // }
    }



    handleInputChange = (e, path) => {
        if (path === undefined) {
            path = [e.target.name];
        }
        const value = e.target.value;
        const checked = e.target.checked;
        this.setState((previousState) => {
            const newState = _.cloneDeep(previousState);
            this.setPath(newState.ourData, path, typeof this.getPath(newState.ourData, path) == 'boolean' ? checked : value);
            return newState;
        });
    }

    parseJSONParam = (paramJSON) => {
        return JSON.parse(JSON.parse(paramJSON))
    }

    fixArray = (a) => {
        return a.filter(item => item !== '').concat(['']);
    }

    fixArrayPath = (obj, path) => {
        if (path.length > 2) {
            this.fixArrayPath(obj[path[0]], path.slice(1));
        } else {

        }
    }

    trimIndex = (a) => {
        const n = parseInt(a[a.length - 1], 10);
        if (!_.isNaN(n)) {
            return a.slice(0, -1);
        }
        return a;
    }

    samePath = (pathA, pathB) => {
        // console.log({pathA, pathB});
        if (pathA === undefined || pathB === undefined) {
            return false;
        }
        return _.isEqual(this.trimIndex(pathA), this.trimIndex(pathB));
    }

    refreshStringArrayReal = (path, focus = true) => {
        let pathWithFocus;
        if (focus) {
            pathWithFocus = this.trimIndex(path);
        } else {
            pathWithFocus = undefined;
        }
        this.setState((previousState) => {
            const newState = { ...previousState, pathWithFocus };
            path = path.slice(0, -1);
            let value = this.getPath(newState.ourData, path);
            value = value.filter(item => item !== '');
            if (this.samePath(path, pathWithFocus)) {
                value = value.concat(['']);
            }
            this.setPath(newState.ourData, path, value);
            return newState;
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            processModalIsOpen: true,
        });

        // console.log(this.state.ourData);
    }

    closeModal = () => {
        this.setState({
            processModalIsOpen: false,
            // outputFilename: null,
        });
    }

    normalize = (string) => {
        return string.toLowerCase().replace(' ', '_');
    }


    login = () => {
        netlifyAuth.authenticate(() => {
            // console.log(netlifyAuth.user);
        });
    };

    renderForm = () => {
        return (
            <div className="columns">
                {Object.keys(this.state.ourData).map((optionName, i) => {
                    if (optionName === 'name') {
                        return '';
                    }
                    return (
                        <div key={i} className="column">
                            <div className="field">
                                <ConfigOptionInput name={optionName} />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    clearAll = () => {
        localStorage.removeItem('ourData');
        localStorage.removeItem('theirData');
        window.location = window.location.href.replace(window.location.search, '');
    }

    combineData = (ourData, theirData, name) => {
        // Shallow copy is fine since we are using recursion
        const newData = {...ourData};
        for (let [key, value] of Object.entries(newData)) {
            if (typeof value == 'object') {
                newData[key] = this.combineData(value, theirData !== undefined && theirData[key] ? theirData[key] : undefined, name);
            } else if (typeof value == 'boolean') {
                const existingData = [].concat(theirData !== undefined && theirData[key] ? theirData[key] : []);
                newData[key] = value ? existingData.concat([name]) : existingData;
            }
        }
        return newData;
    }

    urlEncodeJSON = (json) => {
        return encodeURIComponent(JSON.stringify(JSON.stringify(json)));;
    }

    renderEtcetera = () => {
        return (
            <div className="container">
                <div className="field">
                    <label className="label">Keep the ball rolling...</label>
                    <p>You can copy a link with everyone's responses or just your own</p>
                </div>
                <div className="field">
                    <div className="field-body">
                        <div className="field is-expanded">
                            <div className="field has-addons">
                                <p className="control">
                                    <button className="button" data-clipboard-target="#share-all-text">Copy link to all responses</button>
                                </p>
                                <p className="control is-expanded">
                                    <input id="share-all-text" className={inputClasses} type="text" readOnly value={window.location.href.split('?')[0] + '?json=' + this.urlEncodeJSON(this.combineData(this.state.ourData, this.state.theirData, this.state.ourData.name))} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <div className="field-body">
                        <div className="field is-expanded">
                            <div className="field has-addons">
                                <p className="control">
                                    <button className="button" data-clipboard-target="#share-mine-text">Copy link to my availability</button>
                                </p>
                                <p className="control is-expanded">
                                    <input id="share-mine-text" className={inputClasses} type="text" readOnly value={window.location.href.split('?')[0] + '?json=' + this.urlEncodeJSON(this.combineData(this.state.ourData, undefined, this.state.ourData.name))} />
                                </p>
                            </div>
                        </div>
                    </div>
                            <p className="help">You can use a service like <a href="https://bitly.com/">https://bitly.com/</a> to shorten the URL if you want. I won't be offended.</p>
                </div>

                <div className="level">
                    <button className="button is-danger" onClick={this.clearAll}>Clear All</button>
                </div>
            </div>
        );
    }

    renderDisabled() {
        const overlayAlpha = 0.8;
        const shadowAlpha = overlayAlpha;
        // const roundness = 20;
        const blur = '25px';
        const spread = blur;
        // const  = 20;

        const borderRadius = '100px';
        const backgroundColor = `rgba(0,0,0,${overlayAlpha})`;
        // const boxShadow =`-20px -20px ${blur} ${spread} rgba(0,0,0,${shadowAlpha}), -20px 20px ${blur} ${spread} rgba(0,0,0,${shadowAlpha}), 20px -20px ${blur} ${spread} rgba(0,0,0,${shadowAlpha}), 20px 20px ${blur} ${spread} rgba(0,0,0,${shadowAlpha})`;
        const boxShadow =`0px 0px ${blur} ${spread} rgba(0,0,0,${shadowAlpha})`;
        return (
            // <div className="is-overlay"></div>
            <div style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 2,
                backgroundColor: backgroundColor,
                borderRadius: borderRadius,
                boxShadow: boxShadow,
                // boxShadow: '0px -2px 2px rgba(34,34,34,0.6)',

                padding: '40px',
            }}>
                <p className="title is-3">Start by entering a name by which others will know you.</p>
            </div>
        );
    };

    render() {
        const today = new Date();
        const seconds = today.getSeconds();

        const activities = ['meet', 'game', 'talk', 'chat', 'jam', 'rehearse', 'hang', 'study'];
        const activity = activities[seconds % activities.length]

        return (
            <AppContext.Provider value={{
                ourData: this.state.ourData,
                theirData: this.state.theirData,
                // startDate: this.state.startDate,
                eventDescription: this.state.eventDescription,
                handleInputChange: this.handleInputChange,
                refreshStringArray: this.refreshStringArray,
            }}>
                <div className="App container">
                    <div className="level">
                        <header className="App-header">
                            <h1 className="title is-1">Calico Pick</h1>
                            <p className="has-text-light">Find a time to {activity}...</p>
                        </header>
                    </div>

                    <div className="field is-grouped is-grouped-centered">
                        <div className="field">
                            <label className="label">Event Description</label>
                            <div className="control">
                                <input className={inputClasses} type="text" name="eventDescription" value={this.state.eventDescription}
                                    onChange={this.handleSettingsChange}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Your Name</label>
                            <div className="control">
                                <input className={inputClasses} type="text" name="name" value={this.state.ourData.name}
                                    onChange={(e) => this.handleInputChange(e, ['name'])}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Start Date</label>
                            <div className="control">
                                <DatePicker
                                    className={inputClasses}
                                    name="startDate"
                                    selected={Moment(this.state.startDate).toDate()}
                                    onChange={(value, e) => this.handleDateSettingsChange("startDate", value)}
                                />
                            </div>
                        </div>
                        {/* <div className="field">
                            <label className="label">End Date</label>
                            <div className="control">
                                <DatePicker
                                    className={inputClasses}
                                    name="endDate"
                                    selected={new Date()}
                                    onChange={(value, e) => this.handleSettingsChange("endDate", value.toISOString())}
                                />
                            </div>
                        </div> */}
                    </div>


                    {this.state.ourData.name ? '' : this.renderDisabled()}
                    {this.renderForm()}
                    {this.renderEtcetera()}

                    <div className={this.state.processModalIsOpen ? 'modal is-active' : 'modal'}>
                        <div className="modal-background"></div>
                        <div className="modal-card">
                            <header className="modal-card-head">
                                <p className="modal-card-title">Processing...</p>
                                <button className="delete" aria-label="close" onClick={this.closeModal}></button>
                            </header>
                            <section className="modal-card-body">
                                <div className="level">
                                    <progress className="progress is-small is-dark" max="100" value="100"></progress>
                                </div>

                            </section>
                            <footer className="modal-card-foot">
                                <button className="button" onClick={this.closeModal}>Close</button>
                            </footer>
                        </div>
                    </div>
                </div>
            </AppContext.Provider>
        );
    }
}
