import React, { Component } from 'react';
import Moment from 'moment';
import ReactAddToCalendar from './ReactAddToCalendar';

import List from 'react-list-select'

import 'bulma';
import StringArrayInput from './StringArrayInput';
import { AppContext } from '../context';
import _ from 'lodash';

import "react-datepicker/dist/react-datepicker.css";

export default class ConfigOptionInput extends Component {
    static contextType = AppContext;

    isISODate = (s) => {
        return s.endsWith('Z');
    }

    updateTimes = (selected) => {
        console.log({selected});
        const content = this.context.ourData[this.props.name];
        const path = [this.props.name];

        // console.log({content});
        Object.keys(content).forEach((key, i) => {
            content[key] = selected.includes(i) ? true : false;
        });
        console.log({content});


        const target = {
            name: this.props.name,
            value: content
        }
        // console.log(this.props.name);
        // console.log(content);
        // console.log({target});
        this.context.handleInputChange({
            target
        }, path);
        // this.context.ourData[this.props.name];
    }

    render() {
        const content = this.context.ourData[this.props.name];
        const theirContent = this.context.theirData !== undefined ? this.context.theirData[this.props.name] : {};
        const displayName = this.props.displayName ?? _.startCase(this.props.name);

        if (Array.isArray(content)) {
            if (this.props.name === 'dates') {
                return (
                    <div className="columns">
                        {/* <h3 className="title is-4">{displayName}</h3> */}
                        {Object.keys(content).map((optionName, i) => {
                            // console.log(content[optionName].date);
                            // const eventStartTime = this.context.startTime;
                            // const eventEndTime = this.context.startTime;
                            return (
                                <div key={i} className="column">
                                    <AppContext.Provider value={{
                                        ...this.context,
                                        ourData: content,
                                        theirData: theirContent,
                                        handleInputChange: (e, path) => this.context.handleInputChange(e, [].concat([this.props.name], path === undefined ? [] : path)),
                                    }}>
                                        <ConfigOptionInput name={optionName} displayName="" date={content[optionName].date}/>
                                    </AppContext.Provider>
                                </div>
                            );
                        })}
                    </div>
                );

            }
            if (content.some(item => typeof item !== 'string')) {
                return (
                    <div className="content">
                        <h3 className="title is-4">{displayName}</h3>
                        {Object.keys(content).map((optionName, i) => {
                            return (
                                <div key={i} className="field">
                                    <AppContext.Provider value={{
                                        ...this.context,
                                        ourData: content,
                                        theirData: theirContent,
                                        handleInputChange: (e, path) => this.context.handleInputChange(e, [].concat([this.props.name], path === undefined ? [] : path)),
                                    }}>
                                        <ConfigOptionInput name={optionName} date={this.props.date}/>
                                    </AppContext.Provider>
                                </div>
                            );
                        })}
                    </div>
                );
            }

            return (
                <div className="content">
                    <h3 className="title is-4">{displayName}</h3>
                    <AppContext.Provider value={{
                        ...this.context,
                        content,
                        theirContent,
                        // handleInputChange: (e, path) => this.context.handleInputChange(e, [].concat([this.props.name], path === undefined ? [] : path)),
                        // handleArrayKeyDown: (e, path) => this.context.handleArrayKeyDown(e, [].concat([this.props.name], path === undefined ? [] : path)),
                    }}>
                        <StringArrayInput name={this.props.name} />
                    </AppContext.Provider>
                </div>
            );
        }
        if (typeof content == 'object') {
            if (this.props.name === 'times') {

                const items = Object.keys(content).map((optionName, i) => {
                    const utcTime = parseInt(optionName);
                    const startTime = Moment(new Date((new Date(this.props.date)).valueOf() + utcTime*60*60000)).toISOString();
                    // const checked = this.context.ourData[this.props.name];
                    const participants = this.context.theirData && this.context.theirData[this.props.name] ? this.context.theirData[this.props.name] : [];

                    const endTime = Moment(new Date(startTime.valueOf() + 1*60*60000)).toISOString();
                    // console.log('startTime', startTime);
                    const event = {
                        title: this.context.eventDescription,
                        description: participants.length ? 'With ' + participants.join() : '',
                        location: '',
                        startTime: startTime,
                        endTime,
                    };
                    // let icon = { 'calendar-plus-o': 'left' };



                    return (
                        <div className="columns">
                            <div className="column">
                                {Moment(startTime).format('LT')}
                            </div>
                            <div className="column is-1">
                                <ReactAddToCalendar
                                    event={event}
                                />
                            </div>
                        </div>
                    );
                });

                const timesContent = Object.values(content).reduce((acc, cur, i) => {
                    // console.log({acc, cur, i});
                    if (cur) {
                        acc.push(i);
                        return acc;
                    }
                    return acc;
                }, []);

                return (
                    <div style={{width: "100%"}} className="">
                        <List
                            items={items}
                            selected={timesContent}
                            multiple={true}
                            onChange={(selected) => { this.updateTimes(selected) }}
                        />
                    </div>
                );
            }

            return (
                <div className="content">
                    <h3 className="title is-4">{displayName}</h3>
                    {Object.keys(content).map((optionName, i) => {
                        return (
                            <AppContext.Provider key={i} value={{
                                ...this.context,
                                ourData: content,
                                theirData: theirContent,
                                handleInputChange: (e, path) => this.context.handleInputChange(e, [].concat(this.props.name, path === undefined ? [] : path)),
                                refreshStringArray: (path, focus) => this.context.refreshStringArray([].concat([this.props.name], path === undefined ? [] : path), focus),
                            }}>
                                <div className="field">
                                    <ConfigOptionInput name={optionName} date={this.props.date} />
                                </div>
                            </AppContext.Provider>
                        );
                    })}
                </div>
            );
        }
        if (typeof content == 'boolean') {
            const checked = this.context.ourData[this.props.name];
            const participants = this.context.theirData && this.context.theirData[this.props.name] ? this.context.theirData[this.props.name] : [];

            const endTime = Moment(new Date(this.props.startTime.valueOf() + 1*60*60000));
            // console.log('this.props.startTime', this.props.startTime);
            const event = {
                title: this.context.eventDescription,
                description: participants.length ? 'With ' + participants.join() : '',
                location: '',
                startTime: this.props.startTime,
                endTime,
            };
            // console.log(this.props.startTime);
            // console.log(this.props.startTime.format('LT'));
            // console.log({event});
            return (
                <div className="field">
                    <div className="control">
                        <label className="checkbox">
                            <input className="" type="checkbox" name={this.props.name} checked={checked}
                                onChange={(e) => this.context.handleInputChange(e, [e.target.name])}
                            />&nbsp;
                            {Moment(this.props.startTime).format('LT')}
                            {this.context.theirData && this.context.theirData[this.props.name] && this.context.theirData[this.props.name].length ? ' works for ' : ''}
                            {this.context.theirData && this.context.theirData[this.props.name] ? this.context.theirData[this.props.name].map((theirName) => <span className="tag is-success">{theirName}</span>) : ''}
                        </label>


                        <ReactAddToCalendar event={event}/>
                    </div>
                </div>
            );
        }
        if (typeof content == 'string') {
            if (this.isISODate(content)) {
                return (
                    <div className="field">
                        <h3 className="title is-4">{Moment(content).format("ddd, MMM D")}</h3>
                        {/* <label className="label">{displayName}</label> */}

                        {/* <div className="control">
                            <DatePicker
                                className="input has-background-grey-lighter has-text-dark"
                                name={this.props.name}
                                selected={new Date(content)}
                                onChange={(value, e) => this.context.handleInputChange({ target: { value: value.toUTCString() }}, [this.props.name])}
                            />
                        </div> */}
                    </div>
                );
            }

            return (
                <div className="field">
                    <label className="label">{displayName}</label>
                    <div className="control">
                        <input className="input has-background-grey-lighter has-text-dark" type="text" name={this.props.name} value={content}
                            onChange={(e) => this.context.handleInputChange(e, [e.target.name])}
                        />
                    </div>
                </div>
            );
        }
        if (typeof content == 'number') {
            return (
                <div className="field">
                    <label className="label">{displayName}</label>
                    <div className="control">
                        <input className="input has-background-grey-lighter has-text-dark" type="number" name={this.props.name} value={content}
                            onChange={(e) => this.context.handleInputChange(e, [e.target.name])}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div className="control has-text-danger">
                The option "{this.props.name}" cannot be displayed because the "{typeof content}" type is not supported.
            </div>
        );

    }
}